import React, { FC, useEffect } from 'react';

import style from './ProductContent.module.css';
import { IBigSalmonImage } from '../../interfaces';
import ProductDescription from '../ProducrDescription/ProductDescription';
import SalmonDescription from '../SalmomDescription/SalmonDescription';

const ProductContent: FC<{ img: IBigSalmonImage }> = ({
    img: {
        isRed, src, alt, isBigSalmon, description, title, weight, subDescription,
    },
}) => {
    const split = description.split(' ');
    const desc1 = split.slice(0, 90).join(' ');
    const desc2 = split.slice(90, split.length - 1).join(' ');

    useEffect(() => {
        const element = document.getElementById('scroll_prawn_seafood_page');

        element.scrollIntoView({ behavior: 'smooth' });
    }, [src]);
    return (
        <>
            <div className={style.product__content_wrap_dekstop}>
                <div className={style.product__content_wrap}>
                    <div className={style.product__content}>
                        <div className={style.product__content__information}>
                            <span id='scroll_prawn_seafood_page' />
                            <h2 className={style.product__content_title}>
                                {title}
                            </h2>

                            <p className={style.product__content__information_description}>{description}</p>
                            { subDescription && (
                                <div className={style.product__content__information_second}>
                                    <div className={style.product__content__information_description}>
                                        <span className={style.product__content__information_sub_description}>
                                            Completamente rifilato:
                                        </span>
                                        <p>{subDescription}</p>
                                    </div>
                                </div>
                            ) }
                        </div>

                        <div>
                            <div className={isBigSalmon ? style.product__content_circle_11 : style.product__content_circle}>
                                <img src={src} alt={alt} />
                                { weight && <ProductDescription weight={weight} title={title} /> }
                            </div>

                            { isRed && <SalmonDescription /> }
                        </div>
                    </div>
                </div>
            </div>
            {/* mobile */}
            <div className={style.product__content_wrap_mobile}>
                <div className={(!isRed && !desc2) ? style.product__content_wrap_v2 : style.product__content_wrap}>
                    <div className={style.product__content}>
                        <div className={style.product__content__information}>
                            <span id='scroll_prawn_seafood_page' />
                            <h2 className={style.product__content_title}>
                                {title}
                            </h2>

                            <p className={style.product__content__information_description_1}>{desc1}</p>
                        </div>

                        <div className={style.product__content_circle_wrap}>
                            <div className={isBigSalmon ? style.product__content_circle_11 : style.product__content_circle}>
                                <img src={src} alt={alt} />
                                { weight && <ProductDescription weight={weight} title={title} /> }
                            </div>

                            { isRed && <SalmonDescription /> }
                        </div>
                    </div>

                    { (!subDescription && !!desc2.length) && (
                        <div className={style.test}>
                            <p className={style.product__content__information_description_2}>{desc2}</p>
                        </div>
                    ) }

                    { subDescription && (
                        <div className={style.product__content__information_second}>
                            <div className={style.product__content__information_description}>
                                <span className={style.product__content__information_sub_description}>Completamente rifilato: </span>
                                <p>{subDescription}</p>
                            </div>
                        </div>
                    ) }
                </div>
            </div>
        </>
    );
};

export default ProductContent;
