import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { pathConstant } from '../../../../constants';
import style from './BurgerComponent.module.css';

const BurgerComponent: FC<{ toggleBurger: () => void }> = ({ toggleBurger }) => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ul className={style.burger__menu} onClick={toggleBurger}>
        <li>
            <Link to={pathConstant.home} className={style.burger__menu_a}>I nostri prodotti</Link>
        </li>

        <li>
            <Link to={pathConstant.whoWeAre} className={style.burger__menu_a}>Chi siamo</Link>
        </li>

        <li>
            <Link to={pathConstant.contacts} className={style.burger__menu_a}>Contatti</Link>
        </li>
    </ul>
);

export default BurgerComponent;
