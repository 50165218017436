import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PrawnSeaFoodTitle, ProductsCarousel } from '../../components';
import ProductContent from '../../components/ProductContent/ProductContent';
import img1 from '../../files/photos/products/sea/Rectangle 245.png';
import img2 from '../../files/photos/products/sea/Rectangle 246.png';
import img3 from '../../files/photos/products/sea/Rectangle 247.png';
import img4 from '../../files/photos/products/sea/Rectangle 248.png';
import img5 from '../../files/photos/products/sea/Rectangle 249.png';
import img6 from '../../files/photos/products/sea/Rectangle 251.png';
import salmon1 from '../../files/photos/products/salmon/Rectangle 248.png';
import salmon2 from '../../files/photos/products/salmon/Group 598.png';
import salmon3 from '../../files/photos/products/salmon/Rectangle 248 (1).png';
import salmon4 from '../../files/photos/products/salmon/Group 599.png';
import salmon5 from '../../files/photos/products/salmon/Rectangle 250.png';
import redsalmon1 from '../../files/photos/products/red-salmon/Rectangle 248.png';
import redsalmon2 from '../../files/photos/products/red-salmon/Rectangle 254.png';
import redsalmon3 from '../../files/photos/products/red-salmon/Rectangle 252.png';
import redsalmon4 from '../../files/photos/products/red-salmon/Rectangle 248 (1).png';
import appetizer1 from '../../files/photos/products/appetizer/Rectangle 245 (1).png';
import appetizer2 from '../../files/photos/products/appetizer/Rectangle 246 (1).png';
import { IBigSalmonImage } from '../../interfaces';
import { productsInformationConstant } from '../../constants';

const PrawnSeafoodPage:FC = () => {
    const [img, setImg] = useState<IBigSalmonImage>({
        src: '', alt: '', isBigSalmon: false, description: '', title: '', weight: '', subDescription: '', isRed: false,
    });
    const { pathname } = useLocation();

    useEffect(() => {
        const index = pathname.split('/')[2];
        // eslint-disable-next-line default-case
        switch (index) {
        case '1':
            setImg({
                src: img1,
                alt: '',
                title: productsInformationConstant['1'].title,
                description: productsInformationConstant['1'].description,
            });
            break;
        case '2':
            setImg({
                src: img2,
                alt: '',
                title: productsInformationConstant['2'].title,
                description: productsInformationConstant['2'].description,
            });
            break;
        case '3':
            setImg({
                src: img3,
                alt: '',
                title: productsInformationConstant['3'].title,
                description: productsInformationConstant['3'].description,
            });
            break;
        case '4':
            setImg({
                src: img4,
                alt: '',
                title: productsInformationConstant['4'].title,
                description: productsInformationConstant['4'].description,
            });
            break;
        case '5':
            setImg({
                src: img5,
                alt: '',
                title: productsInformationConstant['5'].title,
                description: productsInformationConstant['5'].description,
            });
            break;
        case '6':
            setImg({
                src: img6,
                alt: '',
                title: productsInformationConstant['6'].title,
                description: productsInformationConstant['6'].description,
            });
            break;
        case '7':
            setImg({
                src: salmon1,
                alt: '',
                title: productsInformationConstant['7'].title,
                description: productsInformationConstant['7'].description,
                weight: productsInformationConstant['7'].weight,
            });
            break;
        case '8':
            setImg({
                src: salmon2,
                alt: '',
                title: productsInformationConstant['8'].title,
                description: productsInformationConstant['8'].description,
                weight: productsInformationConstant['8'].weight,
            });
            break;
        case '9':
            setImg({
                src: salmon3,
                alt: '',
                title: productsInformationConstant['9'].title,
                description: productsInformationConstant['9'].description,
                weight: productsInformationConstant['9'].weight,
            });
            break;
        case '10':
            setImg({
                src: salmon4,
                alt: '',
                title: productsInformationConstant['10'].title,
                description: productsInformationConstant['10'].description,
                weight: productsInformationConstant['10'].weight,
            });
            break;
        case '11':
            setImg({
                src: salmon5,
                alt: '',
                title: productsInformationConstant['11'].title,
                description: productsInformationConstant['11'].description,
                weight: productsInformationConstant['11'].weight,
                isBigSalmon: true,
            });
            break;
        case '12':
            setImg({
                src: redsalmon1,
                alt: '',
                title: productsInformationConstant['12'].title,
                description: productsInformationConstant['12'].description,
                weight: productsInformationConstant['12'].weight,
                isBigSalmon: false,
                subDescription: productsInformationConstant['12'].subDescription,
                isRed: true,
            });
            break;
        case '13':
            setImg({
                src: redsalmon2,
                alt: '',
                title: productsInformationConstant['12'].title,
                description: productsInformationConstant['12'].description,
                weight: productsInformationConstant['12'].weight,
                isBigSalmon: false,
                subDescription: productsInformationConstant['12'].subDescription,
                isRed: true,
            });
            break;
        case '14':
            setImg({
                src: redsalmon3,
                alt: '',
                title: productsInformationConstant['12'].title,
                description: productsInformationConstant['12'].description,
                weight: productsInformationConstant['12'].weight,
                isBigSalmon: false,
                subDescription: productsInformationConstant['12'].subDescription,
                isRed: true,
            });
            break;
        case '15':
            setImg({
                src: redsalmon4,
                alt: '',
                title: productsInformationConstant['12'].title,
                description: productsInformationConstant['12'].description,
                weight: productsInformationConstant['12'].weight,
                isBigSalmon: true,
                subDescription: productsInformationConstant['12'].subDescription,
                isRed: true,
            });
            break;
        case '16':
            setImg({
                src: appetizer1,
                alt: '',
                title: productsInformationConstant['13'].title,
                description: productsInformationConstant['13'].description,
                weight: productsInformationConstant['13'].weight,
                isBigSalmon: false,
            });
            break;
        case '17':
            setImg({
                src: appetizer2,
                alt: '',
                title: productsInformationConstant['14'].title,
                description: productsInformationConstant['14'].description,
                weight: productsInformationConstant['14'].weight,
                isBigSalmon: false,
            });
        }
    }, [pathname]);
    return (
        <div>
            <ProductsCarousel />
            <PrawnSeaFoodTitle />

            <ProductContent img={img} />
        </div>
    );
};

export default PrawnSeafoodPage;
