import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import style from './SiteMap.module.css';
import padding from '../../styles/mainPadding.module.css';
import { pathConstant } from '../../constants';

const SiteMapPage: FC = () => (
    <div id={padding.mainPadding} className={style.sitemap}>
        <h3>Pagine</h3>

        <ul>
            <li><Link to={pathConstant.home}>I nostri prodotti</Link></li>
            <li><Link to={pathConstant.whoWeAre}>Chi siamo</Link></li>
            <li><Link to={pathConstant.contacts}>Contatti</Link></li>
            {/* <li><Link to={pathConstant.faq}>FAQ</Link></li> */}
            <li><Link to={pathConstant.salmon}>Salmone</Link></li>
            <li><Link to={pathConstant.prawnsSeafood}>Gamberi & Frutti di Mare page</Link></li>
            <li><Link to={pathConstant.foodService}>Foodservice</Link></li>
            <li><Link to='#'>Cerca nel sito</Link></li>
        </ul>
    </div>
);

export default SiteMapPage;
