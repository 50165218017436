import React, { FC, useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import style from './ProductsCarousel.module.css';
import { carouselConstant } from '../../constants';
import img1 from '../../files/photos/banner/products/Yoshi EE OÜ - Fabio - Smoked salmon - 2301231101_WEB 1.png';
import img2 from '../../files/photos/banner/products/Yoshi EE OÜ - Fabio - Smoked salmon - 2301231101_WEB 2.png';
import img3 from '../../files/photos/banner/products/Yoshi EE OÜ - Fabio - Smoked salmon - 2301231101_WEB 3.png';
import img4 from '../../files/photos/banner/products/Yoshi EE OÜ - Fabio - Smoked salmon - 2301231101_WEB 4.png';
import img5 from '../../files/photos/banner/products/Yoshi EE OÜ - Fabio - Smoked salmon - 2301231101_WEB 5.png';

export const ProductsCarousel: FC = () => {
    const [images, setImage] = useState<string[]>([]);
    const [interval] = useState<number>(carouselConstant.interval);

    useEffect(() => {
        if (!images.length) {
            setImage([img1, img2, img3, img4, img5]);
        }
    }, []);

    return (
        <Carousel fade className={style.products__carousel}>
            {images.map((img, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Carousel.Item interval={interval} key={index}> <img src={img} alt='img' /> </Carousel.Item>
            ))}
        </Carousel>
    );
};
