import React, { FC } from 'react';

import MainWhoWeAre from '../../components/whoWeAre/MainWhoWeAre/MainWhoWeAre';
import DescriptionWhoWeAre from '../../components/whoWeAre/DescriptionWhoWeAre/DescriptionWhoWeAre';

const WhoWeArePage: FC = () => (
    <div>
        <MainWhoWeAre />
        <DescriptionWhoWeAre />
    </div>
);

export default WhoWeArePage;
