import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { Carousel } from 'react-bootstrap';
import style from './ProductsPresentation.module.css';
import firstSmallImg from '../../files/photos/homePagePresentation/shrimp (1) 1 (1).png';
import first1SmallImg from '../../files/photos/homePagePresentation/salmon 1 (2).png';
import secondSmallImg from '../../files/photos/homePagePresentation/salad 4.png';
import thirdSmallImg from '../../files/photos/homePagePresentation/salad 5.png';
import fourhSmallImg from '../../files/photos/homePagePresentation/salad 6.png';
import { pathConstant } from '../../constants';
import CarouselArrow from '../CarouselArrow/CarouselArrow';
import ProductPresentation from '../ProductPresentation/ProductPresentation';

export const ProductsPresentation: FC = () => {
    const [interval] = useState<number | null>(1000000);
    const [activeArrow, setActiveArrow] = useState<number>(1);

    // eslint-disable-next-line react/no-unstable-nested-components
    const CustomCarouselArrow = ({ direction, onClick }: any) => (
        <CarouselArrow direction={direction} onClick={onClick} setActiveArrow={setActiveArrow} />
    );

    const [productText, setProductText] = useState<{ first: string, second: string, third: string, fouth: string, fifth: string }>(
        {
            first: 'Linea Gamberi e \n frutti di mare in \n atmosfera \n protettiva',
            second: 'Linea Salmone \n affumicato \n scozzese',
            third: 'Linea Salmone \n affumicato \n norvegese',
            fouth: 'Linea Canapé \n al formaggio fresco',
            fifth: 'Linea \n Foodservice',
        },
    );

    // return (
    //     <ControlCarousel
    //         indicators={false}
    //         prevIcon={activeArrow === 1 ? null : <CustomCarouselArrow direction='prev' onClick={() => setActiveArrow(1)} />}
    //         nextIcon={activeArrow === 2 ? null : <CustomCarouselArrow direction='next' onClick={() => setActiveArrow(2)} />}
    //     >
    //         <ControlCarousel.CarouselItem interval={interval}>
    //             <div className={style.products__presentation}>
    //                 <Link to={`${pathConstant.prawnsSeafood}#prawn__seafood_products`}>
    //                     <div id={style.product__presentation_first} className={style.product__presentation}>
    //                         <div className={style.product__presentation_text}>
    //                             <p>
    //                                 Linea Gamberi e
    //                                 <br />
    //                                 frutti di mare in
    //                                 <br />
    //                                 atmosfera
    //                                 <br />
    //                                 protettiva
    //                             </p>
    //                         </div>
    //
    //                         <div className={style.product__presentation_img}>
    //                             <img src={firstSmallImg} alt='big img' />
    //                         </div>
    //                     </div>
    //                 </Link>
    //
    //                 <Link to={`${pathConstant.prawnsSeafood}#prawn__salmon_products`}>
    //                     <div id={style.product__presentation_second} className={style.product__presentation}>
    //                         <div className={style.product__presentation_text}>
    //                             <p>
    //                                 Linea Salmone
    //                                 <br />
    //                                 affumicato
    //                                 <br />
    //                                 scozzese
    //                             </p>
    //                         </div>
    //                         <div className={style.product__presentation_img}>
    //                             <img src={first1SmallImg} alt='big img' />
    //                         </div>
    //                     </div>
    //                 </Link>
    //                 <Link to={`${pathConstant.prawnsSeafood}#prawn__salmon_red_products`}>
    //                     <div id={style.product__presentation_third} className={style.product__presentation}>
    //                         <div className={style.product__presentation_text}>
    //                             <p>
    //                                 Linea Salmone
    //                                 <br />
    //                                 affumicato
    //                                 <br />
    //                                 norvegese
    //                             </p>
    //                         </div>
    //                         <div className={style.product__presentation_img}>
    //                             <img src={secondSmallImg} alt='big img' />
    //                         </div>
    //                     </div>
    //                 </Link>
    //             </div>
    //         </ControlCarousel.CarouselItem>
    //
    //         <ControlCarousel.CarouselItem interval={interval}>
    //             <div className={style.products__presentation}>
    //                 <Link to={`${pathConstant.prawnsSeafood}#prawn__seafood_products`}>
    //                     <div id={style.product__presentation_fourth} className={style.product__presentation}>
    //                         <div className={style.product__presentation_text}>
    //                             <p>
    //                                 Linea Canapé
    //                                 <br />
    //                                 al formaggio fresco
    //                             </p>
    //                         </div>
    //                         <div className={style.product__presentation_img}>
    //                             <img src={thirdSmallImg} alt='big img' />
    //                         </div>
    //                     </div>
    //                 </Link>
    //
    //                 <Link to={`${pathConstant.prawnsSeafood}#prawn__salmon_products`}>
    //                     <div id={style.product__presentation_fiveth} className={style.product__presentation}>
    //                         <div className={style.product__presentation_text}>
    //                             <p>
    //                                 Linea
    //                                 <br />
    //                                 Foodservice
    //                             </p>
    //                         </div>
    //                         <div className={style.product__presentation_img}>
    //                             <img src={fourhSmallImg} alt='big img' />
    //                         </div>
    //                     </div>
    //                 </Link>
    //
    //                 <Link to={`${pathConstant.prawnsSeafood}#prawn__salmon_red_products`}>
    //                     <div id={style.product__presentation_third} className={style.product__presentation}>
    //                         <div className={style.product__presentation_text}>
    //                             <p>
    //                                 Linea Salmone
    //                                 <br />
    //                                 affumicato
    //                                 <br />
    //                                 norvegese
    //                             </p>
    //                         </div>
    //                         <div className={style.product__presentation_img}>
    //                             <img src={secondSmallImg} alt='big img' />
    //                         </div>
    //                     </div>
    //                 </Link>
    //             </div>
    //         </ControlCarousel.CarouselItem>
    //     </ControlCarousel>
    return (
        <div className={style.products__presentation}>
            <ProductPresentation
                textContent={productText.first}
                isWhiteText
                imgSrc={firstSmallImg}
                link={`${pathConstant.prawnsSeafood}#prawn__seafood_products`}
                bg={style.product__presentation_first}
            />

            <ProductPresentation
                textContent={productText.second}
                isWhiteText
                imgSrc={first1SmallImg}
                link={`${pathConstant.prawnsSeafood}#prawn__salmon_products`}
                bg={style.product__presentation_second}
            />

            <ProductPresentation
                textContent={productText.third}
                isWhiteText={false}
                imgSrc={secondSmallImg}
                link={`${pathConstant.prawnsSeafood}#prawn__salmon_red_products`}
                bg={style.product__presentation_third}
            />

            <ProductPresentation
                textContent={productText.fouth}
                isWhiteText={false}
                imgSrc={thirdSmallImg}
                link={`${pathConstant.prawnsSeafood}#prawn__seafood_products`}
                bg={style.product__presentation_fourth}
            />

            <ProductPresentation
                textContent={productText.fifth}
                isWhiteText={false}
                imgSrc={fourhSmallImg}
                link={`${pathConstant.prawnsSeafood}#prawn__salmon_products`}
                bg={style.product__presentation_fiveth}
            />
        </div>
    );
};
