import React, { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

import style from './ControllerCarousel.module.css';
// import slide from '../../files/photos/banner/background/carousel.png';
// import slide from '../../files/photos/banner/background/carousel_main.png';
import slide from '../../files/photos/banner/background/slide1.png';
import slide2 from '../../files/photos/banner/background/slide2.png';
import slide3 from '../../files/photos/banner/background/slide3.png';
import slide4 from '../../files/photos/banner/background/slide4.png';
// import slide1 from '../../files/photos/banner/background/carousel_1.png';
// import slide3 from '../../files/photos/banner/background/carouzel_3.png';
import { carouselConstant } from '../../constants';
import Title from '../Title/Title';
import FirstSlide from '../banner/FirstSlide/FirstSlide';
import SecondSlide from '../banner/SecondSlide/SecondSlide';
import ThirdSlide from '../banner/ThirdSlide/thirdSlide';
import TitleSecond from '../TitleSecond/TitleSecond';
import FourthSlide from '../banner/FourthSlide/FourthSlide';

const ControlledCarousel: FC = () => {
    const [interval] = useState<number>(carouselConstant.interval);
    const [param, setParams] = useSearchParams();

    const handleSelect = (selectedIndex: number): void => {
        param.append('carousel_tree', (selectedIndex + 1).toString());
        setParams(`carousel_tree=${selectedIndex + 1}`);
    };

    return (
        <Carousel onSelect={handleSelect} fade className={style.controller__carousel}>
            <Carousel.Item className={style.controller__carousel_first_slide} interval={interval}>
                <img
                    className='d-block w-100'
                    src={slide}
                    alt='First slide'
                />
                <div className={style.controller__carousel_first_slide_title}>
                    <Title />
                </div>
                {/* <FirstSlide /> */}
            </Carousel.Item>

            <Carousel.Item className={style.controller__carousel_second_slide} interval={interval}>
                <img
                    className='d-block w-100'
                    src={slide2}
                    alt='Second slide'
                />
                <div className={style.controller__carousel_second_slide_title}>
                    <Title />
                </div>
                {/* <SecondSlide /> */}
            </Carousel.Item>

            <Carousel.Item className={style.controller__carousel_third_slide} interval={interval}>
                <img
                    className='d-block w-100'
                    src={slide3}
                    alt='Third slide'
                />
                <div className={style.controller__carousel_third_slide_title}>
                    <TitleSecond />
                </div>
                {/* <ThirdSlide /> */}
            </Carousel.Item>

            <Carousel.Item className={style.fourth} interval={interval}>
                <img
                    className='d-block w-100'
                    src={slide4}
                    alt='Fourth slide'
                />
                <div className={style.controller__carousel_fourth_slide_title}>
                    <Title />
                </div>
                {/* <FourthSlide /> */}
            </Carousel.Item>
        </Carousel>
    );
};

export default ControlledCarousel;
