import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import style from './Product.module.css';
import { pathConstant } from '../../constants';

export const Product: FC<{ src: string, alt: string, index: number }> = ({ src, alt, index }) => (
    <Link to={`${pathConstant.prawnsSeafood}/${index}`}>
        <div className={style.product}>
            <img src={src} alt={alt} />
        </div>
    </Link>
);
