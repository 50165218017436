import React, { FC } from 'react';

import style from './ContactInfo.module.css';
import img from '../../files/photos/contacts/Group 1185.png';

const ContactInfo: FC = () => (
    <div className={style.contacts__info}>
        <div className={style.contacts__info_address}>
            <h2>Contatti</h2>
            <p>Yoshi EE OÜ <br />
                Katusepapi tn 4/2 <br />
                <b>Tallinn 11415</b>
                <br /> <b>Estonia</b>
            </p>
        </div>

        <div className={style.contacts__info_map}>
            <img src={img} alt='world' />
        </div>

    </div>
);

export default ContactInfo;
