import React, { FC } from 'react';

import style from './PrawnSeaFoodTitle.module.css';

export const PrawnSeaFoodTitle: FC = () => {
    const title = `Scopri la nostra deliziosa gamma pronta all'uso: tramezzini, pasta, insalate, tartine,
            nessun limite alla tua fantasia. Qualità Marelizie da vedere e da gustare`;

    return (
        <div className={style.prawn__seafood_page_title}>
            <p>
                {title}
            </p>
        </div>
    );
};
