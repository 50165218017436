import React, {
    FC, useEffect, useState,
} from 'react';

import style from './PrawnSeafoodPage.module.css';
import img1 from '../../files/photos/products/sea/Rectangle 245.png';
import img2 from '../../files/photos/products/sea/Rectangle 246.png';
import img3 from '../../files/photos/products/sea/Rectangle 247.png';
import img4 from '../../files/photos/products/sea/Rectangle 248.png';
import img5 from '../../files/photos/products/sea/Rectangle 249.png';
import img6 from '../../files/photos/products/sea/Rectangle 251.png';
import {
    PrawnSeaFoodTitle, ProductsCarousel, ProductsSalmons, ProductTitle,
} from '../../components';
import { Products } from '../../components/Products/Products';
import { IImage } from '../../interfaces';
import { categoriesProducts } from '../../constants/categoriesProducts.constant';
import salmon1 from '../../files/photos/products/salmon/Rectangle 248.png';
import salmon2 from '../../files/photos/products/salmon/Group 598.png';
import salmon3 from '../../files/photos/products/salmon/Rectangle 248 (1).png';
import salmon4 from '../../files/photos/products/salmon/Group 599.png';
import salmon5 from '../../files/photos/products/salmon/Rectangle 250.png';
import salmon6 from '../../files/photos/products/red-salmon/Rectangle 248 (1).png';
import salmon7 from '../../files/photos/products/red-salmon/Rectangle 248.png';
import salmon8 from '../../files/photos/products/red-salmon/Rectangle 252.png';
import salmon9 from '../../files/photos/products/red-salmon/Rectangle 254.png';
import imgSalmon from '../../files/photos/homePagePresentation/salmon 1.png';
import cheese from '../../files/photos/homePagePresentation/cheese (1) 1.png';
import imgSea from '../../files/photos/homePagePresentation/shrimp (1) 1.png';
import appetizer from '../../files/photos/products/appetizer/Rectangle 245 (1).png';
import appetizer2 from '../../files/photos/products/appetizer/Rectangle 246 (1).png';
import { ProductsAppetize } from '../../components/ProductsAppetize/ProductsAppetize';

const PrawnsSeafoodPage: FC = () => {
    const [images, setImages] = useState<IImage>({ images: [{ src: '', alt: '' }] });
    const [appetizerImg, setAppetizerImages] = useState<IImage>({ images: [{ src: '', alt: '' }] });
    const [imagesSalmons, setImagesSalmons] = useState<IImage>({ images: [{ src: '', alt: '' }] });
    const [imagesRedSalmons, setImagesRedSalmons] = useState<IImage>({ images: [{ src: '', alt: '' }] });
    const [imageSalmon, setImageSalmon] = useState<{ src: string, alt: string }>({ src: '', alt: '' });
    const [imageRedSalmon, setImageRedSalmon] = useState<{ src: string, alt: string }>({ src: '', alt: '' });

    useEffect(() => {
        setImages({ images: [{ src: img1 }, { src: img2 }, { src: img3 }, { src: img4 }, { src: img5 }, { src: img6 }] });
        setImagesSalmons({ images: [{ src: salmon1 }, { src: salmon2 }, { src: salmon3 }, { src: salmon4 }] });
        setImagesRedSalmons({ images: [{ src: salmon7 }, { src: salmon9 }, { src: salmon8 }] });
        setAppetizerImages({ images: [{ src: appetizer }, { src: appetizer2 }] });
        setImageSalmon({ src: salmon5, alt: '' });
        setImageRedSalmon({ src: salmon6, alt: '' });
    }, []);

    useEffect(() => {
        const anchor = window.location.hash.substring(1);

        const targetElement = document.getElementById(anchor);

        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    }, [images]);

    return (
        <div className={style.prawn__seafood_page_products_wrap}>
            <ProductsCarousel />
            <PrawnSeaFoodTitle />

            <div id='prawn__seafood_products' className={style.prawn__seafood_page_products}>
                <ProductTitle message={categoriesProducts.sea} img={imgSea} />
                <Products images={images.images} />
            </div>

            <div className={style.prawn__seafood_page_products_hr_wrap}>
                <hr className={style.prawn__seafood_page_products_hr} />
            </div>

            <div id='prawn__salmon_products' className={style.prawn__seafood_page_products}>
                <ProductTitle message={categoriesProducts.salmon} img={imgSalmon} />
                <ProductsSalmons images={imagesSalmons.images} mainSalmon={imageSalmon} salmonColor={{ isRed: false }} />
            </div>

            <div id='prawn__salmon_red_products' className={style.prawn__seafood_page_products}>
                <ProductTitle message={categoriesProducts.salmonNorvegese} img={imgSalmon} />
                <ProductsSalmons images={imagesRedSalmons.images} mainSalmon={imageRedSalmon} salmonColor={{ isRed: true }} />
            </div>

            <div id='prawn__appetizer_products' className={style.prawn__seafood_page_products}>
                <ProductTitle message={categoriesProducts.fresco} img={cheese} />
                <ProductsAppetize images={appetizerImg.images} />
            </div>
        </div>
    );
};

export default PrawnsSeafoodPage;
