import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { pathConstant } from '../../constants';
import style from '../ProductsPresentation/ProductsPresentation.module.css';
import { IProductPresentation } from '../../interfaces';

const ProductPresentation: FC<IProductPresentation> = ({
    link, textContent, imgSrc, isWhiteText, bg,
}) => (
    <Link to={link}>
        <div className={`${bg} ${style.product__presentation}`}>
            <div className={style.product__presentation_text}>
                <p className={
                    isWhiteText ? style.product__presentation_p_white : style.product__presentation_p_black
                }
                >{textContent}
                </p>
            </div>

            <div className={style.product__presentation_img}>
                <img src={imgSrc} alt='big img' />
            </div>
        </div>
    </Link>
);

export default ProductPresentation;
