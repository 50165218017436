import React, { FC } from 'react';

import style from './TitleSecond.module.css';

const TitleSecond: FC = () => {
    const titleSecondMainTextFirst = 'Scopri la nostra linea';
    const titleSecondMainTextSecond = 'di specialità di mare';
    const titleSecondMainTextThird = 'pronte all\'uso!';
    const titleSecondMainTextFourth = 'riciclabilità e nessun packaging superfluo';

    return (
        <div className={style.title__second}>
            <h1 className={style.title__second__main_text}>
                {titleSecondMainTextFirst}
                <br />
                {titleSecondMainTextSecond}
                <br />
                {titleSecondMainTextThird}
            </h1>
            <p className={style.title__second__second_text}>
                <span className={style.title__second_text_space}>Confezione</span> trasparente, massima
                <br />
                {titleSecondMainTextFourth}
            </p>
        </div>
    );
};

export default TitleSecond;
