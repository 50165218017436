import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../files/photos/logo/logo-marelise.png';
import style from './Logo.module.css';
import { pathConstant } from '../../constants';

const Logo: FC = () => {
    const [isFirstVisiting, setIsFirstVisiting] = useState<boolean>(true);
    const [isActive, setIsActive] = useState<boolean>(false);

    const handleMouse = () => {
        setIsActive(!isActive);

        if (isFirstVisiting) setIsFirstVisiting(false);
    };

    return (
        <div onMouseEnter={handleMouse} onMouseLeave={handleMouse} className={style.logo}>
            <Link to={pathConstant.home}>
                <img src={logo} alt='logo' />
                { !isFirstVisiting && <div className={isActive ? style.logo__active : style.logo__leave} /> }
            </Link>
        </div>
    );
};

export default Logo;
