import React, { FC, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import style from './Search.module.css';

const Search: FC<{ handleClick: () => void }> = ({ handleClick }) => {
    const [showSearch, setShowSearch] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        document.head.append(script);
        script.src = 'https://cse.google.com/cse.js?cx=8449b5dd9fc944ba3';

        setTimeout(() => {
            setShowSearch(true);
        }, 100);
    }, []);

    const searchClass = `${style.footer__search} ${showSearch ? style.show : ''}`;

    return (
        <div className={searchClass}>
            <CloseIcon className={style.closeIcon} onClick={handleClick} width={20} height={20} />
            <div className='gcse-search' />
        </div>
    );
};

export default Search;
