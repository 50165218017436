import React, { FC } from 'react';

import style from './DescriptionWhoWeAre.module.css';

const DescriptionWhoWeAre: FC = () => (
    <div className={style.description__who_we_are_wrap}>
        <div className={style.description__who_we_are_img} />
        <div className={style.description__who_we_are}>
            <div className={style.who_we_are_hr_mobile} />
            <div>
                <p>
                    La nostra missione è quella di offrire ai nostri clienti prodotti di alta qualità, già
                    porzionati e pronti per essere gustati, con un packaging minimalista, trasparente e
                    riciclabile. Siamo convinti che la qualità parli da sola e per questo abbiamo scelto di
                    renderla evidente fin dalla fase di acquisto, attraverso la nostra confezione che
                    permette di apprezzare appieno la freschezza e la bontà dei nostri prodotti.
                </p>
            </div>
            <div className={style.who_we_are_hr} />
            <div className={style.description__who_we_are_second_p}>
                <p>
                    Siamo orgogliosi di offrire prodotti della pesca e dell’acquacoltura sostenibili,
                    garantendo allo stesso tempo la massima qualità e trasparenza. Scegli
                    Marelizie e assapora il gusto autentico del mare di qualità!
                </p>
            </div>
        </div>
    </div>
);

export default DescriptionWhoWeAre;
