import React, { FC } from 'react';

import style from './Title.module.css';

const Title: FC = () => {
    const mainText = 'Scopri la nostra linea';
    const mainTextSecond = 'di specialità di mare';
    const mainTextThird = 'pronte all\'uso!';

    const secondText = 'riciclabilità e nessun packaging superfluo';
    return (
        <div className={style.title}>
            <h1 className={style.title__main_text}>
                {mainText}
                <br />
                {mainTextSecond}
                <br />
                {mainTextThird}
            </h1>
            <p className={style.title__second_text}>
                <span className={style.title__second_text_space}>Confezione</span> trasparente, massima
                <br />
                {secondText}
            </p>
        </div>
    );
};

export default Title;
