export const productsInformationConstant = {
    1: {
        title: 'Cocktail di Mare',
        description: 'Il nostro Cocktail di Mare è una classica combinazione di totani, cozze e gamberetti, '
            + 'selezionati per la massima freschezza. I totani apportano una '
            + 'consistenza tenera, le cozze offrono un sapore delicato e succulento, mentre i gamberetti aggiungono '
            + 'dolcezza e croccantezza. Perfetto per antipasti, '
            + 'insalate o piatti principali come zuppe e risotti, il Cocktail di Mare soddisfa tutti i palati,'
            + ' come per tutti gli altri prodotti della linea, se gustati in un piatto '
            + 'caldo, vanno aggiunti solo a fine cottura.',
    },
    2: {
        title: 'Mazzancolle Tropicali',
        description: 'Le nostre Mazzancolle Tropicali provengono da allevamenti a bassa densità, '
            + 'dove vengono coltivate con cura per garantire la massima qualità del '
            + 'prodotto. Queste mazzancolle si distinguono per il loro caratteristico colore tigrato e '
            + 'per la consistenza ferma e leggermente croccante al morso, che le '
            + 'rende perfette per molteplici preparazioni culinarie sia fredde che calde, come pasta e risotti.',
    },
    3: {
        title: 'Mazzancolle tropicali BIO',
        description: 'Le Mazzancolle Tropicali Bio, provenienti da allevamenti biologici certificati. Il loro sapore delicato'
            + ' e la consistenza ferma e croccante le rendono perfette per molteplici utilizzi in cucina, '
            + 'dall\'antipasto al primo piatto, un secondo veloce e fino alla pizza gourmet.',
    },
    4: {
        title: 'Mazzancolle tropicali con coda',
        description: 'Le nostre Mazzancolle Tropicali provengono da allevamenti a bassa densità, '
            + 'dove vengono coltivate con cura e rispetto per l\'ambiente. '
            + 'Grazie alla loro consistenza ferma e leggermente croccante al morso e al '
            + 'loro delicato sapore sono perfette per essere gustate come antipasto o finger food, magari accompagnate '
            + 'da una salsa o da un\'insalata fresca, tenendole per la coda per gustarle in modo ancora più comodo e divertente.',
    },
    5: {
        title: '“Gamberi Mari Freddi” (Coldwater shrimp)',
        description: 'I nostri Gamberi Mari Freddi sono pescati nelle acque tra la Groenlandia e l\'Islanda, '
            + 'una delle zone più incontaminate del mondo, e vengono\n'
            + 'immediatamente sgusciati e cotti a bordo della nave per garantire la massima freschezza.'
            + ' Questo processo di lavorazione rapido e accurato permette ai\n'
            + 'nostri gamberi dal colore rosato di conservare tutta la loro bontà e succosità, rendendoli '
            + 'particolarmente adatti per preparazioni fredde come insalate e\n'
            + 'tramezzini.',
    },
    6: {
        title: 'Cocktail di Mare',
        description: `I Gamberi Argentini sono tra i migliori gamberi al mondo. Pescati nell'Atlantico meridionale, sono caratterizzati dal gusto dolce e le striature rosso acceso che li rendono facilmente riconoscibili. La loro consistenza morbida li rende particolarmente adatti per le preparazioni in cui si richiede una texture delicata e piacevole al palato. La loro qualità viene garantita dal fatto che vengono pescati in acque incontaminate e lavorati con cura per preservarne il sapore unico. Sono ideali sia per i piatti caldi che per quelli freddi, e si prestano a molteplici preparazioni. In caso di uso in un piatto caldo si raccomanda di aggiungerli solo all’ultimo momento`,
        weight: '',
    },
    7: {
        title: 'Salmone Scozzese affumicato a fetta lunga',
        description: `Il nostro Salmone Affumicato Scozzese a fetta lunga è un'autentica prelibatezza. Prodotto con salmone di alta qualità proveniente dalle fredde acque scozzesi, è affumicato con maestria per ottenere un sapore ricco e intenso. L'affettatura a fetta lunga consente di selezionare solo il centro del filetto, la parte più morbida e gustosa. 
            Le fette lunghe di salmone affumicato sono caratterizzate da una consistenza delicata e morbida, che si scioglie in bocca. Il suo gusto leggermente affumicato e la nota dolce del salmone lo rendono perfetto da gustare da solo o come ingrediente
             principale in piatti gourmet. 
            
            La qualità del nostro Salmone Affumicato Scozzese è garantita dalla cura con cui selezioniamo e affumichiamo il pesce. Ogni fetta rappresenta un'eccellenza culinaria, ideale per antipasti, insalate, bagel o come ingrediente in piatti di pasta o riso.`,
        weight: '50g',
    },
    8: {
        title: 'Salmone Scozzese affumicato a fetta lunga',
        description: `Il nostro Salmone Affumicato Scozzese a fetta lunga è un'autentica prelibatezza. Prodotto con salmone di alta qualità proveniente dalle fredde acque scozzesi, è affumicato con maestria per ottenere un sapore ricco e intenso. L'affettatura a fetta lunga consente di selezionare solo il centro del filetto, la parte più morbida e gustosa. 
        
        Le fette lunghe di salmone affumicato sono caratterizzate da una consistenza delicata e morbida, che si scioglie in bocca. Il suo gusto leggermente affumicato e la nota dolce del salmone lo rendono perfetto da gustare da solo o come ingrediente
         principale in piatti gourmet. 
        
        La qualità del nostro Salmone Affumicato Scozzese è garantita dalla cura con cui selezioniamo e affumichiamo il pesce. Ogni fetta rappresenta uneccellenza culinaria, ideale per antipasti, insalate, bagel o come ingrediente in piatti di pasta o riso.`,
        weight: '80g',
    },
    9: {
        title: 'Toko',
        description: 'Toko è un filetto di di Salmone Affumicato che puoi affettare a casa nella maniera desiderata: taglio lungo, diagonale, sashimi, carpaccio, tartare o qualsiasi altro modo suggerito dalla tua fantasia per le tue ricette. La sua forma particolare consente una lavorazione in purezza eccezionale, preservando integralmente le sue proprietà organolettiche. Porta in tavola il fascino dell;affumicatura artigianale e godi del gusto che lo rende un\'esperienza culinaria straordinaria.',
        weight: 'Filletto 250g',
    },
    10: {
        title: 'Salmone Scozzese affumicato a fetta lunga',
        description: `Il nostro Salmone Affumicato Scozzese a fetta lunga è un'autentica prelibatezza. Prodotto con salmone di alta qualità proveniente dalle fredde acque  scozzesi, è affumicato con maestria per ottenere un sapore ricco e intenso. L'affettatura a fetta lunga consente di selezionare solo il centro del filetto, la  parte più morbida e gustosa. 
             
             Le fette lunghe di salmone affumicato sono caratterizzate da una consistenza delicata e morbida, che si scioglie in bocca. Il suo gusto leggermente affumicato e la nota dolce del salmone lo rendono perfetto da gustare da solo o come ingrediente
              principale in piatti gourmet. 
             
             La qualità del nostro Salmone Affumicato Scozzese è garantita dalla cura con cui selezioniamo e affumichiamo il pesce. Ogni fetta rappresenta un'eccellenza culinaria, ideale per antipasti, insalate, bagel o come ingrediente in piatti di pasta o riso.`,
        weight: '160g',
    },
    11: {
        title: 'Filetto intero di salmone scozzese',
        description: `Il nostro Filetto di Salmone Affumicato Scozzese da 1 kg è la scelta ideale per ristoranti e servizi di ristorazione. Prodotto con salmone di alta qualità proveniente dalle fredde acque scozzesi, rappresenta un'opzione affidabile e versatile per soddisfare le esigenze del Foodservice.
            
            Questo filetto di salmone affumicato è caratterizzato da una lavorazione meticolosa che preserva la sua consistenza delicata e il sapore ricco e affumicato.  La sua presentazione in un formato da 1 kg consente di gestire facilmente le porzioni e adattarle alle necessità dei tuoi clienti.
             
            Il nostro Salmone Affumicato Scozzese è prodotto con la massima cura e attenzione alla qualità, garantendo un'esperienza  culinaria superba. È perfetto per essere utilizzato in vari piatti, come antipasti, insalate, panini gourmet  o come ingrediente in ricette di pasta o riso`,
        weight: '1kg',
    },
    12: {
        title: 'Filetto di Salmone affumicato Norvegese',
        description: `Vieni a scoprire il sapore autentico del mare con il nostro SalmoneAffumicato Norvegese, un capolavoro culinariolavorato dal fresco pergarantirti un'esperienza sensoriale senza pari.Il nostro processo di produzione inizia con il pescato più fresco eprelibato, direttamente dalle acquecristalline della Norvegia. Ognipassaggio del processo è curato con attenzione per preservare ladelicatezza e la naturale succulenza del salmone.`,
        subDescription: `Ogni filetto di salmone viene rifilato a mano daesperti mastri affumicatori, rimuovendo con cura ogni imperfezione pergarantirti una consistenza perfetta e un aspetto irresistibile.La salatura a secco: untocco di maestria nel salare a secco ognifiletto di salmone dona un sapore ricco e profondo, enfatizzando le notegustative uniche del pesce. Questo metodo tradizionale di salatura è unodei segreti dietro il nostro sapore inconfondibile.La asciugatura ad aria naturale: la semplice circolazione di aria frescaasciuga il prodotto in modo naturale concentrandone gusto e "umami",fino ad un livello ottimale per il prossimo passaggioLa affumicatura con una miscela esclusiva di legni di faggio/quercia:una lenta affumicatura con fumo filtrato da ogni impurità esalta ilgusto del prodotto e ne facilita la conservazione.Confezione interamente trasparente: vogliamo che tu possa ammirarel'incredibile freschezza e qualità del salmone prima ancora di aprirla.`,
        weight: '',
    },
    13: {
        title: 'Canapé al formaggio fresco e salmone affumicato',
        description: `Scopri l'armonia perfetta tra il salmone affumicato e il formaggiofresco nei nostri esclusivi Canapé. Ogni boccone è un equilibrio tra ladelicatezza del pesce e la morbida cremosità del formaggio, creandoun'esperienza culinaria unica. Perfetti per condividere momenti specialio per deliziarti con un tocco di classe.`,
        weight: '',
    },
    14: {
        title: 'Canapé al formaggio fresco e pomodori secchi',
        description: `Lasciati tentare dalla combinazione sorprendente dei Canapé al FormaggioFresco e Pomodori Secchi. La morbida consistenza del formaggio si fondecon il gusto intenso dei pomodori secchi, regalando un'esplosione disapori in ogni morso. Un'opzione ideale per arricchire le tue occasioni,portando una nota di freschezza e originalità alla tua tavola.`,
        weight: '',
    },
};
