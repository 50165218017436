import React, { FC } from 'react';

import style from './Contacts.module.css';
import ContactsForm from '../../components/Contacts/ContactsForm';
import ContactInfo from '../../components/ContactInfo/ContactInfo';

const ContactsPage: FC = () => (
    <div id={style.contacts}>
        <h2 className={style.contacts__title}>Facciamo una chiacchierata</h2>
        <ContactsForm />
        <ContactInfo />
    </div>
);

export default ContactsPage;
