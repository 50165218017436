import React, { FC, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Alert, Button } from '@mui/material';
import { joiResolver } from '@hookform/resolvers/joi';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';

import style from './ContactsForm.module.css';
import { IFormContactsUs } from '../../interfaces';
import { formFieldsContactsUs, responseConstant } from '../../constants';
import { validators } from '../../utils';
import { contactUsService } from '../../services';
import { IErrorResponse } from '../../interfaces/errorResponse.interface';

const ContactsForm: FC = () => {
    const { contactsUsSchema } = validators;

    const [isMessageWasWentOnEmail, setIsMessageWasWentOnEmail] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [value, setValue] = useState<string>();

    const {
        reset, formState: { errors }, handleSubmit, register,
    } = useForm<IFormContactsUs>({ resolver: joiResolver(contactsUsSchema), mode: 'onTouched' });

    const onSubmit: SubmitHandler<IFormContactsUs> = async (data) => {
        try {
            // eslint-disable-next-line no-console
            if (errors) console.error(errors);

            const { data: responseData } = await contactUsService.sendMessageOnEMail(data);

            if (responseData.data === responseConstant.OK) {
                reset();
                setIsMessageWasWentOnEmail(!isMessageWasWentOnEmail);
            }
        } catch (e) {
            const err = (e as AxiosError).response.data;
            const responseError = err as IErrorResponse;

            setErrorMessage(responseError.error);
        }
    };

    return (
        <div className={style.contacts__form}>
            { errorMessage && <Alert severity='error'>{errorMessage}</Alert> }
            { isMessageWasWentOnEmail && <Alert severity='success'>Il messaggio è stato inviato!</Alert> }
            <form onSubmit={handleSubmit(onSubmit)} className={style.contacts__form}>
                <span className={style.contacts__form_span}>Il tuo nome</span>
                <TextField
                    error={!!Object.keys(errors).includes('name')}
                    fullWidth
                    label={formFieldsContactsUs.name}
                    helperText={errors?.name?.message}
                    {...register('name')}
                />
                <span className={style.contacts__form_span}>la tua email</span>
                <TextField
                    error={!!Object.keys(errors).includes('email')}
                    fullWidth
                    label={formFieldsContactsUs.email}
                    helperText={errors?.email?.message}
                    {...register('email')}
                />
                <span className={style.contacts__form_span}>Il tuo numero di telefono</span>
                <TextField
                    error={!!Object.keys(errors).includes('phone')}
                    fullWidth
                    label={formFieldsContactsUs.phone}
                    helperText={errors?.phone?.message}
                    {...register('phone')}
                />
                <span className={style.contacts__form_span}>Messaggi</span>
                <TextField
                    style={{ backgroundColor: 'white' }}
                    error={!!Object.keys(errors).includes('message')}
                    multiline
                    rows={4}
                    label={formFieldsContactsUs.message}
                    helperText={errors?.message?.message}
                    {...register('message')}
                />
                <Button
                    style={{ backgroundColor: '#40649E', marginTop: '10px' }}
                    type='submit'
                    className={style.contacts__form_btn}
                    variant='contained'
                >Inviare il messaggio
                    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'>
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M4.56564 1.94064C4.7365 1.76979 5.0135 1.76979 5.18436 1.94064L10.4344
                               7.19064C10.6052 7.3615 10.6052 7.6385 10.4344 7.80936L5.18436 13.0594C5.0135
                                13.2302 4.7365 13.2302 4.56564 13.0594C4.39479 12.8885 4.39479 12.6115
                                4.56564 12.4406L9.50628 7.5L4.56564 2.55936C4.39479 2.3885 4.39479
                                2.1115 4.56564 1.94064Z'
                            fill='white'
                        />
                    </svg>
                </Button>
            </form>
        </div>
    );
};

export default ContactsForm;
