import React, { FC } from 'react';

import { ProductsPresentation } from '../../components';
import ControlledCarousel from '../../components/ControlledCarousel/ControlledCarousel';

const OutProductsPage: FC = () => (
    <div>
        <ControlledCarousel />
        <ProductsPresentation />
    </div>
);

export default OutProductsPage;
