import React, { FC } from 'react';

import style from './ProductsAppetize.module.css';
import { IImage } from '../../interfaces';
import { ProductAppetize } from '../ProductAppetize/ProductAppetize';

export const ProductsAppetize: FC<IImage> = ({ images }) => (
    <div className={style.products_appetize}>
        {/* eslint-disable-next-line react/no-array-index-key */}
        { images.map(({ alt, src }, index) => <ProductAppetize key={index} alt={alt} src={src} index={index + 16} />) }
    </div>
);
