import React, { FC } from 'react';

import style from './ProductTitle.module.css';

export const ProductTitle: FC<{ message: string, img: string }> = ({ message, img }) => (
    <div className={style.product__title}>
        <h2 className={style.product__title_message}>{message}</h2>
        <div>
            <img src={img} alt='small img' />
        </div>
    </div>
);
