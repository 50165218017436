import Joi from 'joi';

import { IFormContactsUs } from '../interfaces';
import { regexConstant } from '../constants';
import { KeyValidationErrorEnum } from '../enums';

export const validators = {
    contactsUsSchema: Joi.object<IFormContactsUs>({
        name: Joi.string()
            .min(2)
            .max(25)
            .lowercase()
            .trim()
            .pattern(regexConstant.letters)
            .disallow(
                Joi.ref('email'),
                Joi.ref('message'),
                Joi.ref('company'),
                Joi.ref('phone'),
            )
            .required()
            .messages({
                [KeyValidationErrorEnum.STRING_EMPTY]: 'Il Nome non può essere un campo vuoto',
                [KeyValidationErrorEnum.STRING_MAX]: 'Nome deve avere una lunghezza non superiore a {#limit}',
                [KeyValidationErrorEnum.STRING_MIN]: 'Nome deve avere una lunghezza di almeno {#limit}',
                [KeyValidationErrorEnum.STRING_PATTERN]: 'Dovrebbero esserci solo lettere',
                [KeyValidationErrorEnum.ANY_INVALID]: 'Il Nome non può essere uguale a email, messaggio, azienda o telefono',
            }),
        email: Joi.string()
            .min(5)
            .max(45)
            .regex(regexConstant.email)
            .lowercase()
            .trim()
            .disallow(
                Joi.ref('message'),
                Joi.ref('company'),
                Joi.ref('phone'),
            )
            .required()
            .messages({
                [KeyValidationErrorEnum.STRING_EMPTY]: 'Email non può essere un campo vuoto',
                [KeyValidationErrorEnum.STRING_MAX]: 'Email deve avere una lunghezza non superiore a {#limit}',
                [KeyValidationErrorEnum.STRING_MIN]: 'Email deve avere una lunghezza di almeno {#limit}',
                [KeyValidationErrorEnum.STRING_PATTERN]: 'Сontrolla l\'ortografia corretta della mail',
                [KeyValidationErrorEnum.ANY_INVALID]: 'Il Email non può essere uguale a nome, messaggio, azienda o telefono',
            }),
        message: Joi.string()
            .min(5)
            .max(1000)
            .lowercase()
            .trim()
            .disallow(
                Joi.ref('company'),
                Joi.ref('phone'),
            )
            .required()
            .messages({
                [KeyValidationErrorEnum.STRING_EMPTY]: 'Messaggio non può essere un campo vuoto',
                [KeyValidationErrorEnum.STRING_MAX]: 'Messaggio deve avere una lunghezza non superiore a {#limit}',
                [KeyValidationErrorEnum.STRING_MIN]: 'Messaggio deve avere una lunghezza di almeno {#limit}',
                [KeyValidationErrorEnum.ANY_INVALID]: 'Il Messaggio non può essere uguale a azienda o telefono',
            }),
        company: Joi.string()
            .min(2)
            .max(40)
            .lowercase()
            .trim()
            .disallow(Joi.ref('phone'))
            .empty('')
            .optional()
            .messages({
                [KeyValidationErrorEnum.STRING_MAX]: 'Azienda deve avere una lunghezza non superiore a {#limit}',
                [KeyValidationErrorEnum.STRING_MIN]: 'Azienda deve avere una lunghezza di almeno {#limit}',
                [KeyValidationErrorEnum.ANY_INVALID]: 'Il Azienda non può essere uguale a telefono',
            }),
        phone: Joi.string()
            .min(8)
            .max(14)
            .trim()
            .empty('')
            .optional()
            .messages({
                [KeyValidationErrorEnum.STRING_MAX]: 'Telefono deve avere una lunghezza non superiore a {#limit}',
                [KeyValidationErrorEnum.STRING_MIN]: 'Telefono deve avere una lunghezza di almeno {#limit}',
            }),
    }),
};
