import React, { FC } from 'react';

import style from './ProductDescription.module.css';

const ProductDescription: FC<{weight: string | undefined, title: string | undefined}> = ({ weight, title }) => (
    <div className={title === 'Toko' ? style.product__description_toko : style.product__description}>
        <p className={style.product__description_kg}>{weight ?? ''}</p>
    </div>
);

export default ProductDescription;
