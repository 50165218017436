import React, { FC } from 'react';

import style from './SalmonDescription.module.css';

const SalmonDescription: FC = () => (
    // eslint-disable-next-line react/react-in-jsx-scope
    <div className={style.salmon__description}>
        Siamo orgogliosi di portare sulla tua
        tavola il nostro SalmoneAffumicato
        Norvegese, un piacere da gustare e
        condividere.
    </div>
);

export default SalmonDescription;
