import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import OutProductsPage from './pages/OurProductsPage/OurProductsPage';
import PrawnsSeafoodPage from './pages/PrawnsSeafoodPage/PrawnsSeafoodPage';
import FoodServicePage from './pages/FoodServicePage/FoodServicePage';
// import SalmonPage from './pages/SalmonPage/SalmonPage';
import WhoWeArePage from './pages/WhoWeArePage/WhoWeArePage';
import ContactsPage from './pages/ContactsPage/ContactsPage';
// import FaqPage from './pages/FaqPage/FaqPage';
import SiteMapPage from './pages/SiteMapPage/SiteMapPage';
import { pathConstant } from './constants';
import PrawnSeafoodPage from './pages/PrawnSeafoodPage/PrawnSeafoodPage';

const App = () => (
    <div className='App'>
        <Routes>
            <Route path={pathConstant.home} element={<Layout />}>
                <Route index element={<OutProductsPage />} />
                <Route path={pathConstant.prawnsSeafood} element={<PrawnsSeafoodPage />} />
                <Route path={`${pathConstant.prawnsSeafood}/:index`} element={<PrawnSeafoodPage />} />
                {/* <Route path={pathConstant.salmon} element={<SalmonPage />} /> */}
                {/* <Route path={pathConstant.foodService} element={<FoodServicePage />} /> */}
                <Route path={pathConstant.whoWeAre} element={<WhoWeArePage />} />
                <Route path={pathConstant.contacts} element={<ContactsPage />} />
                {/* <Route path={pathConstant.faq} element={<FaqPage />} /> */}
                <Route path={pathConstant.sitemap} element={<SiteMapPage />} />
                <Route path='*' element={<Navigate to='/' replace />} />
            </Route>
        </Routes>
    </div>
);

export default App;
