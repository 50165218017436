import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import style from './ProductsSalmons.module.css';
import { IImagesSalmon } from '../../interfaces';
import { ProductSalmon } from '../ProductSalmon/ProductSalmon';
import { pathConstant } from '../../constants';

export const ProductsSalmons: FC<IImagesSalmon> = ({ images, mainSalmon, salmonColor: { isRed } }) => (
    <div className={isRed ? style.products__salmons_red : style.products__salmons}>
        {/* eslint-disable-next-line react/no-array-index-key */}
        { images.map(({ alt, src }, index) => <ProductSalmon key={index} alt={alt} src={src} index={isRed ? index + 12 : index + 7} />) }
        <div className={style.products__salmons_main_wrapper}>
            <Link to={isRed ? `${pathConstant.prawnsSeafood}/15` : `${pathConstant.prawnsSeafood}/11`}>
                <img className={style.products__salmons_main} src={mainSalmon.src} alt={mainSalmon.alt} />
            </Link>
        </div>
    </div>
);
