import React, {FC, useState} from 'react';

import style from './MainWhoWeAre.module.css';
import Carousel from "react-bootstrap/Carousel";
import img from '../../../files/photos/who-we-are/Image.jpeg'
import img2 from '../../../files/photos/who-we-are/Image (1).jpeg'
import img3 from '../../../files/photos/who-we-are/Image (2).jpeg'
import { carouselConstant } from '../../../constants';

const MainWhoWeAre: FC = () => {
    const [interval] = useState<number>(carouselConstant.interval);
    const description = 'Siamo un team di appassionati del mare con oltre trent\'anni di esperienza nel settore. Nel 2022 abbiamo deciso di unire le nostre competenze per portare sulla tavola degli italiani prodotti del mare pronti da consumare con il nostro marchio Marelizie.';

    return (
        <div className={style.main__who_we_are}>
            <h2 className={style.main__who_we_are_title}>Chi siamo</h2>
            <div className={style.main__who_we_are_description}>
                <p>{description}</p>

                <div className={style.carousel}>
                    <Carousel fade controls={false} indicators={false}>
                        <Carousel.Item interval={interval}> <img src={img} alt='img' /> </Carousel.Item>
                        <Carousel.Item interval={interval}> <img src={img2} alt='img' /> </Carousel.Item>
                        <Carousel.Item interval={interval}> <img src={img3} alt='img' /> </Carousel.Item>
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default MainWhoWeAre;
