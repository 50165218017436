export const pathConstant = {
    home: '/',
    prawnsSeafood: '/prawns-seafood',
    salmon: '/salmon',
    foodService: '/food-service',
    whoWeAre: '/who-we-are',
    contacts: '/contacts',
    faq: '/faq',
    sitemap: '/sitemap',
};
