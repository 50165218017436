import React, { FC, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import style from './Header.module.css';
import Logo from '../Logo/Logo';
import { pathConstant } from '../../constants';

const Header: FC = () => {
    const [params] = useSearchParams();
    const [paramValue, setParamValue] = useState<string>(null);

    useEffect(() => {
        setParamValue(params.get('carousel_tree'));
    }, [params]);

    return (
        <header className={paramValue === '3' ? style.header_slide_tree : style.header}>
            <div className={style.header__logo}>
                <Logo />
            </div>

            <nav>
                <ul id={style.header__nav_ul}>
                    <li className={style.header__nav_ul_li}>
                        <Link to={pathConstant.home}>I nostri prodotti</Link>
                    </li>
                    <li className={style.header__nav_ul_li}>
                        <Link to={pathConstant.whoWeAre}>Chi siamo</Link>
                    </li>
                    <li className={style.header__nav_ul_li}>
                        <Link to={pathConstant.contacts}>Contatti</Link>
                    </li>
                    {/* <li> */}
                    {/*    <Link to={pathConstant.faq}>FAQ</Link> */}
                    {/* </li> */}
                </ul>
            </nav>
        </header>
    );
};

export default Header;
