import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import style from './Footer.module.css';
import mail from '../../files/photos/mail/mail.png';
import Logo from '../Logo/Logo';
import { pathConstant } from '../../constants';
import search from '../../files/photos/footer/search-interface-symbol (1).png';
import BurgerComponent from './mobile/Burger/BurgerComponent';
import Search from './mobile/Search/Search';

const Footer:FC = () => {
    const [burgerActive, setBurgerActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);

    const toggleBurger = () => {
        setBurgerActive(!burgerActive);
    };

    const toggleSearch = () => {
        setSearchActive(!searchActive);
    };

    return (
        <footer>
            <div className={style.footer}>
                <div className={style.footer__img}>
                    <Logo />
                </div>

                <div className={style.footer__main}>

                    <ul className={style.footer__main_ul}>
                        <li className={style.footer__main_ul_li}>
                            Cerca nel sito
                        </li>

                        <li className={style.footer__main_ul_li_text}>Text</li>
                        <li className={style.footer__main_ul_li_text}>Text</li>
                        <li className={style.footer__main_ul_li_text}>Text</li>
                    </ul>

                    <ul className={style.footer__main_ul}>
                        <li className={style.footer__main_ul_li}>
                            <Link to={pathConstant.sitemap}>Mappa del sito</Link>
                        </li>

                        <li className={style.footer__main_ul_li_text}>
                            <Link to={pathConstant.home}>I nostri prodotti</Link>
                        </li>

                        <li className={style.footer__main_ul_li_text}>
                            <Link to={pathConstant.whoWeAre}>Chi siamo</Link>
                        </li>

                        <li className={style.footer__main_ul_li_text}>
                            <Link to={pathConstant.contacts}>Contatti</Link>
                        </li>

                        {/* <li className={style.footer__main_ul_li_text}> */}
                        {/*    <Link to={pathConstant.faq}>FAQ</Link> */}
                        {/* </li> */}
                    </ul>

                    <ul className={style.footer__main_ul}>
                        <li className={style.footer__main_ul_li}>
                            <Link to={pathConstant.contacts}>Contatti</Link>
                        </li>

                        <li className={style.footer__main_ul_contacts}>
                            <img src={mail} alt='mail' />
                            <p>Mandaci un messaggio</p>
                        </li>
                    </ul>

                </div>
            </div>

            <div className={style.footer__mobile}>
                <div className={!searchActive ? style.footer__mobile_main : ''}>
                    { !searchActive ? (
                        <>
                            { !burgerActive && (
                                <div
                                    className={style.footer__mobile_main_wrap}
                                    onClick={toggleSearch}
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        height='28'
                                        viewBox='0 -960 960 960'
                                        width='28'
                                        fill='#FFFFFF'
                                        stroke='#FFFFFF'
                                        strokeWidth='1.5'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                    >
                                        <path d='M784-120 532-372q-30 24-69 38t-83 14q-109
                                0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0
                                 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56
                                  56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75
                                   0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z'
                                        />
                                    </svg>
                                    <p className={style.footer__mobile_main_text}>Cerca nel sito</p>
                                </div>
                            ) }

                            { !burgerActive ? (
                                <div className={style.footer__mobile_main_wrap} onClick={toggleBurger}>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='28' viewBox='0 0 24 20' fill='none'>
                                        <path
                                            d='M1 1H23M1
                    7L23 7M1 13H23M1 19L23 19'
                                            stroke='#FFFFFF'
                                            strokeWidth='1.5'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                        />
                                    </svg>
                                    <p className={style.footer__mobile_main_text}>Mappa del sito</p>
                                </div>
                            ) : <BurgerComponent toggleBurger={toggleBurger} />}

                            { !burgerActive && (
                                <div className={style.footer__mobile_main_wrap}>
                                    <Link to={pathConstant.contacts}>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 20' fill='none'>
                                            <path
                                                d='M27 3V17C27 18.1046 26.1046 19 25 19H3C1.89543 19
                    1 18.1046 1 17V3M27 3C27 1.89543 26.1046 1 25 1H3C1.89543 1
                    1 1.89543 1 3M27 3L15.1384 11.2119C14.4535 11.686 13.5465 11.686 12.8616
                     11.2119L1 3'
                                                stroke='#FFFFFF'
                                                strokeWidth='1.5'
                                                strokeLinecap='round'
                                                strokeLinejoin='round'
                                            />
                                        </svg>
                                        <p className={style.footer__mobile_main_text}>Contatti</p>
                                    </Link>
                                </div>
                            ) }
                        </>
                    ) : <Search handleClick={toggleSearch} /> }
                </div>
            </div>
        </footer>
    );
};

export default Footer;
