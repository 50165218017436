import React, { FC } from 'react';

import style from './Products.module.css';
import { Product } from '../Product/Product';
import { IImage } from '../../interfaces';

export const Products: FC<IImage> = ({ images }) => (
    <div className={style.products}>
        {/* eslint-disable-next-line react/no-array-index-key */}
        { images.map(({ alt, src }, index) => <Product key={index} alt={alt} src={src} index={index + 1} />) }
    </div>
);
